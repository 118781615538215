import { FormEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoginStatus from '../hooks/LoginStatus'

export default function Home() {
    const navigate = useNavigate()
    const [errorText, setErrorText] = useState("")
    const { loginStatus, checkLoginStatus } = useLoginStatus();

    useEffect(() => {
        checkLoginStatus()
    }, []);


    function sendVerificationRequest(e: FormEvent<HTMLFormElement>) {
        e.preventDefault(); // stop page refresh
        const formData = new FormData(e.currentTarget);

        // const code = formData.get("verifCode");

        fetch("/api/verify-user", {method: 'POST', body: formData})
        .then(res => {
            if (res.ok) {
                navigate("/")
                return
            } else {
                return res.json()
            }
        })
        .then(data => {
            if (data.error) {
                setErrorText("Error: "+(data.error as string))
            }
        })
        .catch(error => {console.error(error)})

    }

    return (
            <div className="w-full grid grid-cols-1 place-items-center">
                {loginStatus === 0 ? (
                    <form onSubmit={sendVerificationRequest} className='w-full bg-zinc-50 max-w-xl p-4 shadow rounded-xl grid place-items-center gap-6'>
                            <div id="formError" className='w-full text-center text-red-500'>{errorText}</div>
                            <h2 className='text-xl'>Please enter your login code</h2>
                            <input type="text" minLength={12} maxLength={12} name="verifCode" id="verifCode" className='input mb-2 w-full max-w-60' placeholder='e.g. j3Ic82CU1agmQT7X' required />
                            <button formAction='submit' className='btn bg-gray-100 text-lg font-semibold'>Verify</button>
                    </form>
                ) : (
                    <div className="">
                        <div id="formError" className='h-full w-full text-2xl align-middle justify-center text-center py-12 text-red-500'>You shouldn't be here...</div>
                    </div>
                )}
            </div>
    );
}

import React from 'react';

export default function SigninModal() {
    function sendSignin(event: React.FormEvent<HTMLFormElement>) {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      let formError = document.getElementById('formError') as HTMLInputElement

      fetch('/api/signin', {method: 'POST', body: formData})
        .then(response => response.json())
        .then(data => {
          // console.log('Data:', data);
          if (data.error) {
            formError.innerText = data.error;
          } else {
            // formError.innerText = "Logged in";
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      // alert(`Email: ${email} \n Password: ${password}`);
    }
    function openSignupModal() {
      let signin = document.getElementById('signinModal') as HTMLDialogElement
      let signup = document.getElementById('signupModal') as HTMLDialogElement
      signin.close()
      signup.showModal()
    }
    return (
      <>
        <dialog id="signinModal" className="modal">
          <div className="modal-box w-11/12 max-w-2xl">
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form>
            <h2 className="text-2xl font-bold text-center w-full">Sign in</h2>
            <form onSubmit={sendSignin} className='grid place-items-center w-full'>
              <input type="email" name="email" placeholder="Email" className="max-w-lg input input-bordered w-full mt-4" required />
              <input type="password" name="password" placeholder="Password" className="max-w-lg input input-bordered w-full mt-4" required />
              <div className='grid place-items-center mt-4'>
                <button className="btn text-lg" type="submit">Sign in</button>
                <p id="formError" className='text-error mt-2'></p> {/* for errors */}
              </div>
            </form>
            <div className='w-full grid place-items-center mt-2'>
              <p onClick={openSignupModal} className="w-full text-center underline text-zinc-500 cursor-pointer">I dont have an account</p>
            </div>
          </div>
        </dialog>
      </>
    );
  }

interface UserSettingsProps {
    loginStatus: number;
    maxSections: number;
    totalWatchingCount: number;
}

export default function App({loginStatus, maxSections, totalWatchingCount}: UserSettingsProps) {

    return (
        <>
            {loginStatus > 0 &&
                <div className="my-4 gap-2 py-2 px-4 flex items-center align-middle justify-center w-full max-w-2xl shadow-sm rounded-lg bg-white bg-opacity-50 glass">
                    <div className="bg-zinc-50 p-2 rounded my-auto">
                        <p><b className="font-bold">{totalWatchingCount}</b> out of <b className="font-bold">{maxSections}</b>  remaining sections</p>
                    </div>
                    <div className="tooltip form-control mx-auto mt-2" data-tip="[SOON] Notify me when a course I'm watching adds a new section/lab/tutorial">
                        <input type="checkbox" className="toggle" disabled defaultChecked />
                    </div>
                </div>
            }
        </>
    )
}

import React from 'react';

export default function SignupModal() {
  function sendSignup(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    // const email = formData.get("email");
    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");

    let formError = document.getElementById('formError') as HTMLInputElement
    if (password !== confirmPassword) {
      formError.innerText = 'Passwords do not match';
      return;
    }

    fetch('/api/signup', {method: 'POST', body: formData})
      .then(response => response.json())
      .then(data => {
        // console.log('Data:', data);
        if (data.error) {
          formError.innerText = data.error;
        } else {
          formError.innerText = "";
          openSigninModal();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    // alert(`Email: ${email}\nPassword: ${password}\nConfirm Password: ${confirmPassword}`);
  }
  function openSigninModal() {
    let signin = document.getElementById('signinModal') as HTMLDialogElement
    let signup = document.getElementById('signupModal') as HTMLDialogElement
    signup.close()
    signin.showModal()
  }
    return (
      <>
        <dialog id="signupModal" className="modal">
          <div className="modal-box w-11/12 max-w-2xl">
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form>
            <h2 className="text-2xl font-bold text-center w-full">Sign up</h2>
            <form onSubmit={sendSignup} className='grid place-items-center w-full'>
            {/* <label className="input input-bordered flex items-center gap-2 w-full mt-4 max-w-lg">
              +1
              <input type="tel" name="phone" id="suphone" placeholder="Phone Number" className="" required />
            </label> */}
              <input type="email" name="email" placeholder="Email" className="max-w-lg input input-bordered w-full mt-4" required />
              <input type="password" name="password" minLength={8} placeholder="Password" className="max-w-lg input input-bordered w-full mt-4" required />
              <input type="password" name="confirmPassword" minLength={8} placeholder="Confirm Password" className="max-w-lg input input-bordered w-full mt-4" required />
              <label className="cursor-pointer label w-4/5 mt-2">
                <input type="checkbox" className="checkbox m-auto" required />
                <span className="text-sm text-center pl-4 m-auto text-gray-500">I acknlowedge that cheatseat will send me emails for course sections that I decide to watch, that cheatseat is not guaranteed to get me into any course, and that cheatseat is not affiliated with McMaster University.</span>
              </label>
              <div className='grid place-items-center mt-4'>
                <button className="btn text-lg" type="submit">Sign up</button>
                <p id="formError" className='text-error mt-2'></p> {/* for errors */}
              </div>
            </form>
            <div className='w-full grid place-items-center mt-2'>
              <p onClick={openSigninModal} className="w-full text-center underline text-zinc-500 cursor-pointer">I already have an account</p>
            </div>
          </div>
        </dialog>
      </>
    );
  }

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import SigninModal from './SigninModal'
import SignupModal from './SignupModal'

import useLoginStatus from '../hooks/LoginStatus'

function signout() { // must be done on server since we used the httponly flag
    fetch("/api/logout", {method: 'POST'})
    .then(res => {
        if (res.ok) {
            window.location.reload();
        }
    })
    .catch((error) => {
        console.error('Error:', error);
    });

    // window.location.reload();
}

export default function Topbar() {
    const { loginStatus, checkLoginStatus } = useLoginStatus();

    useEffect(() => {
        checkLoginStatus()
    }, []);

    return (
        <>
        <div className='fixed flex top-0 w-full glass bg-opacity-10 left-0 px-2 shadow-sm bg-stone-100 py-2 z-20'> {/*max-w-[1300px] left-1/2 transform -translate-x-1/2*/}
            <a href="/" className='truncate no-underline text-gray-700 flex-1 text-xl leading-10 m-auto md:text-2xl ml-4 text-left w-full h-max-auto font-[550]'>🚨 cheatseat 🚨</a>
            <div className='mt-1 text-right h-full divide-x-4 divide-transparent flex'>
                {loginStatus < 0 ? (
                    <div className='flex-1 flex gap-2'>
                        <button className="btn btn-sm w-auto bg-zinc-50 rounded-full" onClick={()=>(document.getElementById('signupModal') as HTMLDialogElement).showModal()}>sign up</button>
                        <button className="btn btn-sm bg-zinc-50 rounded-full" onClick={()=>(document.getElementById('signinModal') as HTMLDialogElement).showModal()}>sign in</button>
                        <SigninModal />
                        <SignupModal />
                    </div>
                ) : (
                    <button className="btn btn-outline btn-sm bg-zinc-100 flex-1 rounded-full" onClick={signout}>sign out</button>
                )}

                <a href="https://github.com/cttps"><FontAwesomeIcon className="h-8 mx-2" icon={faGithub} /></a>
            </div>
        </div>
        </>
    );
  }

// import { time } from "console";
import WatchButton from "./WatchButton";

interface courseProps {
    courseName: string;
    courseTerm: string;
    status: string;
    numSeats: number;
    numWatching: number;
    userWatchingList: any[];
    lastFetched: number;
    setTotalWatchingCount: Function;
    totalWatchingCount: number;
    courseSections: any[];
    maxSections: number;
}

// These are arbitrary
const watchingRedLimit = 29;
const numSeatsYellowLimit = 10;

let curTime = Date.now()/1000;


function CourseTags({courseName, courseTerm, status, numSeats, numWatching, userWatchingList, lastFetched, setTotalWatchingCount, totalWatchingCount, courseSections, maxSections}: courseProps ){
    let displayedTime = "now"
    let timeDiff = curTime - lastFetched;
    if (timeDiff > 604800) {
        displayedTime = Math.floor(timeDiff / 604800) + "w"
    } else if (timeDiff > 86400) {
        displayedTime = Math.floor(timeDiff / 86400) + "d"
    } else if (timeDiff > 3600) {
        displayedTime = Math.floor(timeDiff / 3600) + "h"
    } else if (timeDiff > 60) {
        displayedTime = Math.floor(timeDiff / 60) + "m"
    } else if (timeDiff > 0) {
        displayedTime = Math.floor(timeDiff) + "s"
    }

    let statusElement = <p className="select-none flex-initial ml-4 text-green-500 bg-green-200 bg-opacity-50 px-3 h-8 text-sm font-semibold rounded-full min-h-8 flex-shrink-0 items-center inline-flex">Open - {numSeats} seats left</p>
    if (status === "f") {
        statusElement = <p className="select-none flex-initial ml-4 text-rose-700 bg-rose-400 bg-opacity-50 px-3 h-8 text-sm font-semibold rounded-full min-h-8 flex-shrink-0 items-center inline-flex">Full</p>
    } else if (status === "w") {
        statusElement = <p className="select-none flex-initial ml-4 text-orange-600 bg-orange-200 bg-opacity-50 px-3 h-8 text-sm font-semibold rounded-full min-h-8 flex-shrink-0 items-center inline-flex">Waitlist available</p>
    } else {
        if (numSeats < numSeatsYellowLimit) {
            statusElement = <p className="select-none flex-initial ml-4 text-yellow-500 bg-yellow-200 bg-opacity-50 px-3 h-8 text-sm font-semibold rounded-full min-h-8 flex-shrink-0 items-center inline-flex">Near full - {numSeats} seats left</p>
        }
    }
    let usersWatchingElement = <p className="select-none flex-initial ml-4 bg-green-200 bg-opacity-50 text-green-400 px-3 h-8 text-sm font-normal rounded-full min-h-8 flex-shrink-0 items-center inline-flex">0 👤 Waiting</p>
    if (numWatching > watchingRedLimit) {
        usersWatchingElement = <p className="select-none flex-initial ml-4 bg-red-200 bg-opacity-50 text-red-500 px-3 h-8 text-sm font-normal rounded-full min-h-8 flex-shrink-0 items-center inline-flex">{numWatching} 👤 Waiting</p>
    } else if (numWatching > 0) {
        usersWatchingElement = <p className="select-none flex-initial ml-4 bg-amber-100 bg-opacity-50 text-amber-400 px-3 h-8 text-sm font-normal rounded-full min-h-8 flex-shrink-0 items-center inline-flex">{numWatching} 👤 Waiting</p>
    }

    return (
        <>
            <p className="text-xl whitespace-nowrap md:text-2xl font-semibold text-zinc-500">{courseName}</p>
            <p className="align-middle items-center flex-shrink-0 inline-flex ml-4 text-lg font-medium text-zinc-400">{courseTerm}</p>
            <p className="max-sm:hidden align-middle items-center flex-shrink-0 inline-flex flex-1 ml-4 text-md font-normal text-zinc-300">🔄 {displayedTime}</p>
            {statusElement}
            {usersWatchingElement}
            <WatchButton setTotalWatchingCount={setTotalWatchingCount} totalWatchingCount={totalWatchingCount} watchingList={userWatchingList} courseName={courseName} courseTerm={courseTerm} courseSections={courseSections} maxSections={maxSections}/>
        </>
    );
}

export default function Course(props: courseProps) {
    return (
        <>
            <div className="w-full py-4 px-8 flex hover:bg-zinc-100 align-left flex-wrap gap-2">
                <CourseTags {...props} />
            </div>
        </>
    );
}

// <div className="w-full py-4 px-8 flex hover:bg-zinc-100 align-left">
// <p className="text-2xl font-semibold text-zinc-500"> COMPSCI 3GC3 </p>
// <p className="align-middle items-center flex-shrink-0 inline-flex flex-1 ml-4 text-md font-normal text-zinc-300">last updated: now</p>
// <p className="select-none flex-initial ml-4 text-rose-700 bg-rose-400 bg-opacity-50 px-3 h-8 text-sm font-bold rounded-full min-h-8 flex-shrink-0 items-center inline-flex">Full</p>
// <p className="select-none flex-initial ml-4 bg-amber-100 bg-opacity-50 text-amber-300 px-3 h-8 text-sm font-medium rounded-full min-h-8 flex-shrink-0 items-center inline-flex">1 👤 Waiting</p>
// <button className="btn btn-sm rounded-full ml-4 btn-active btn-outline ">Watching</button>
// </div>

// let courses = []
// courses.push(
// )
// courses.push(
// <div className="w-full py-4 px-8 flex hover:bg-zinc-100 align-left">
//     <p className="text-2xl font-semibold text-zinc-500"> IARTS 1PA3</p>
//     <p className="align-middle items-center flex-shrink-0 inline-flex flex-1 ml-4 text-md font-normal text-zinc-300">last updated: now</p>
//     <p className="select-none flex-initial ml-4 text-green-500 bg-green-200 bg-opacity-50 px-3 h-8 text-sm font-bold rounded-full min-h-8 flex-shrink-0 items-center inline-flex">Open - 30 seats left</p>
//     <p className="select-none flex-initial ml-4 bg-green-200 bg-opacity-50 text-green-400 px-3 h-8 text-sm font-medium rounded-full min-h-8 flex-shrink-0 items-center inline-flex">0 👤 Waiting</p>
//     <button className="btn btn-sm rounded-full ml-4">Watch</button>
// </div>
// )

import Course from './Course'
import React, { useState, useEffect } from 'react';
import UserSettings from "./UserSettings"

let loading: any[] = []
for (let i = 0; i < 12; i++) {
    loading.push(
        <div className="w-full py-4 px-8 grid place-items-center hover:bg-zinc-100 align-left flex-wrap gap-2">
            <span className="loading loading-dots loading-md translate-y-1 bg-zinc-500 text-zinc-500"></span>
        </div>
    )
}

const maxSections = 40

interface CourseListProps {
    searchQuery: string;
    loginStatus: number;
}


export default function CourseList({ searchQuery, loginStatus }: CourseListProps) {
    const [courses, setCourses] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([])
    const [totalWatchingCount, setTotalWatchingCount] = useState(0)
    const [uWL, setuWL] = useState([])

    function compareCourses(a: any, b: any) {
        let founda = false
        let foundb = false
        for (let i = 0; i < uWL.length; i++) {
            let watching = (uWL[i] as any)
            if (watching.coursekey === a.key && watching.term == a.term) {
                founda = true
            }
            if (watching.coursekey === b.key && watching.term == b.term) {
                foundb = true
            }
        }
        // if (founda && foundb) { return 0; }
        if (founda) { return -1; }
        if (foundb) { return 1; }


        let numSeatsA = 0
        let numSeatsB = 0

        for (let j = 0; j < a.opensections.length; j++) {
            numSeatsA += parseInt(a.opensections[j].openseats)
        }
        for (let j = 0; j < b.opensections.length; j++) {
            numSeatsB += parseInt(b.opensections[j].openseats)
        }

        if (numSeatsA > numSeatsB) {
            return 1
        } else if (numSeatsA < numSeatsB) {
            return -1
        } else {
            if (a.Key < b.Key) {
                return -1
            } else {
                return 1
            }
        }
        // return 0
    }


    // On page load
    useEffect(() => {
        fetch('/api/courses')
        .then(response => response.json())
        .then(data => {
            console.log(data)
            let coursedata = data.courses
            let userWatchingList = data.usercourses
            setuWL(userWatchingList)

            console.log("AYAAAAA: ",userWatchingList.length)
            setTotalWatchingCount(userWatchingList.length)

            coursedata.sort(compareCourses)

            let courseElements = coursedata.map((course: any) => {
                let numSeats = 0
                for (let j = 0; j < course.opensections.length; j++) {
                    numSeats += parseInt(course.opensections[j].openseats)
                }

                return (
                    <Course key={course.key}
                        courseName={(course.key).replace('-',' ')}
                        courseTerm={course.term}
                        status={course.status}
                        numSeats={numSeats}
                        numWatching={course.totalwatching}
                        userWatchingList={userWatchingList}
                        courseSections={course.sections}
                        setTotalWatchingCount={setTotalWatchingCount}
                        totalWatchingCount={userWatchingList.length}
                        lastFetched={course.lastfetched}
                        maxSections={maxSections}
                    />
                )
            })
            setCourses(courseElements)
        })
        .catch(error => { console.log(error) })
    }, [totalWatchingCount]) // last array is the dependency array and it being empty = we only run once on mount. if anything in the dependency array changes, the effect func will rerun

    // On search query changed
    useEffect(() => {
        const filtered = courses.filter((course: JSX.Element) =>
            (course.props.courseName as string).toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredCourses(filtered);
    }, [searchQuery, courses]);

    return (
        <>
            <UserSettings loginStatus={loginStatus} maxSections={maxSections} totalWatchingCount={totalWatchingCount}/>
            <div className="w-full md:max-w-4xl overflow-visible grid grid-cols-1 divide-y place-items-center bg-zinc-50 shadow-2xl z-10 rounded-xl" >
                {filteredCourses}
                {courses.length === 0 &&
                    <div className='w-full h-full grid place-items-center rounded-xl'>
                        {loginStatus === -1 &&
                            <div className="p-3 z-30 text-white text-xl font-semibold w-full justify-center align-middle text-center rounded-t-xl bg-red-500">
                                Please log in
                            </div>
                        }
                        {loginStatus === 0 &&
                            <div className="p-3 z-30 text-white text-xl font-semibold w-full justify-center align-middle text-center rounded-t-xl bg-amber-500">
                                A verification email was sent to your email address. Verify <a href="./verify-email">here</a>.
                                <br/>
                                <p className='text-lg font-[550]'>This could take up to 10 minutes. Sorry 😓</p>
                            </div>
                        }
                        <div className='w-full h-full grid place-items-center mt-4'>
                            <div className={`w-full h-full ${loginStatus < 1 ? "blur-sm" : ""} z-10 top-0 bottom-0 left-0 right-0`}>
                                {loading}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

// import LandingPageBg from '../components/LandingPageBg';
import Search from '../components/Search';
import CourseList from '../components/CourseList';
import LandingPage from '../components/LandingPage';

import useLoginStatus from '../hooks/LoginStatus';
import { useState, useEffect } from 'react';


export default function Home() {
    const [curSearch, setCurSearch] = useState("")
    const {loginStatus, checkLoginStatus} = useLoginStatus()

    useEffect(() => {
        checkLoginStatus()
    }, []);

    if (loginStatus > -1) {
        return (
            <div className="w-full grid grid-cols-1 place-items-center pt-10 pb-6 md:pt-48 md:pb-24">
            <div className='relative'>
                <div className='absolute top-0 bottom-0 scale-y-[120%] left-0 right-0 bg-[#fefefe] -z-10 blur-3xl'></div>
                <h1 className='text-2xl md:text-6xl max-w-3xl blur-none z-20 font-[550] text-center mt-4 mb-0 select-none '>The Best</h1>
                <h1 className='text-2xl md:text-6xl max-w-3xl blur-none z-20 font-[550] text-center mb-4 select-none '>McMaster Course Sniper</h1>
            </div>
            <Search setSearch={setCurSearch} />
            <CourseList loginStatus={loginStatus} searchQuery={curSearch} />
        </div>
        )
    } else {

        return ( <LandingPage/>)
    }
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

interface SearchProps {
    setSearch: (query: string) => void;
}

export default function Search({ setSearch }: SearchProps) {

    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        let searchQuery = e.currentTarget.value;
        console.log(searchQuery)
        setSearch(searchQuery)
    }

    return (
        <>
            <label className="w-full md:max-w-3xl relative mb-2">

                <p className='hover:!cursor-pointer z-10 text-neutral'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute text-zinc-500 w-8 h-8 top-1/2 left-full -translate-x-16 transform -translate-y-1/2 " />
                </p>
                <input type="text" onChange={handleTextChange} placeholder="Type your course name" className="rounded-full text-lg p-7 max-w-3xl w-full shadow-xl bg-white input input-bordered" />
            </label>
        </>
    );
}

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import VerifyEmail from './pages/VerifyEmail'
import Unsubscribe from './pages/Unsubscribe'
import Topbar from './components/Topbar'
import Footer from './components/Footer'

function App() {
  return (
    <React.StrictMode>
      <Router >
        <div className="App">
          <Topbar />
          <div className='grid mt-4 place-items-center min-h-[99vh]'>
            <div className='w-full text-center max-w-7xl'>
              <Routes>
                <Route path="/" element={ <Home/> } />
                <Route path="/verify-email" element={ <VerifyEmail/> } />
                <Route path="/unsubscribe" element={ <Unsubscribe/> } />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </Router>
    </React.StrictMode>
  );
}

export default App;

import { useState, useEffect } from 'react';

const useLoginStatus = () => {
  const [loginStatus, setLoginStatus] = useState(-1);

  const checkLoginStatus = async () => {
    fetch('/api/loginstatus', { method: 'GET' })
    .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          setLoginStatus(-1);
        }
    })
    .then(data => {
        setLoginStatus(data.status);
    })
    .catch(error => {
        console.error('Error checking login status:', error);
        setLoginStatus(-1);
    });
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return { loginStatus, checkLoginStatus };
};

export default useLoginStatus;

import React, { useState, useEffect } from 'react';

type Props = {
    watchingList: any[]
    courseName: string
    courseTerm: string
    courseSections: any[]
    maxSections: number
    setTotalWatchingCount: Function
    totalWatchingCount: number
}

type CourseChunk = {
    sectionnames: string[]
    coursekey: string,
    term: string,
}

const sectionWarningLimit = 10

export default function WatchButton({watchingList, courseName, courseTerm, courseSections, setTotalWatchingCount, totalWatchingCount, maxSections}: Props) {
    const [watchedSections, setWatchedSections] = useState<string[]>([]);
    const [isWatched, setIsWatched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tooManySections, setTooManySections] = useState(false)

    useEffect(() => {

        console.log("Wathcing list",watchingList)
        console.log("Course Name",courseName)

        let watchedArr = []
        for (let i = 0; i < (watchingList.length); i++) {
            if (watchingList[i].term === courseTerm && watchingList[i].coursekey.replace("-", " ") === courseName) {
                watchedArr.push(watchingList[i].sectionid)
            }
        }
        setIsWatched((watchedArr).length > 0);
        setWatchedSections(watchedArr);
    }, [watchingList, courseName, courseTerm, courseSections, totalWatchingCount]);

    const handleWatchChange = (sections: string[]) => {
        if ((sections.length - watchedSections.length) + totalWatchingCount > maxSections) {
            setTooManySections(true)
            setTimeout(() => {
                setTooManySections(false)
            },2000)
            return
        }

        if (isLoading) { return }

        setIsLoading(true);

        const payload: CourseChunk = {
            term: courseTerm,
            coursekey: courseName.replace(" ", "-"),
            sectionnames: sections,
        };

        console.log("paypay",payload)

        fetch('/api/watchcourse', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setWatchedSections(sections);
            setIsWatched(sections.length > 0);
            setTotalWatchingCount((sections.length - watchedSections.length) + totalWatchingCount)
        })
        .catch(error => {
            console.error('Error updating watched sections:', error);
        })
        .finally(() => {
            setTimeout(() => {setIsLoading(false)},500)
        });
    };

    const handleSectionToggle = (event: React.ChangeEvent<HTMLInputElement>, sectionName: string) => {
        event.stopPropagation() // this stops dropdown from closing whenever a box is ticked
        const newWatchedSections = watchedSections.includes(sectionName)
            ? watchedSections.filter(name => name !== sectionName)
            : [...watchedSections, sectionName];
        handleWatchChange(newWatchedSections);
    };

    const handleWatchAllToggle = () => {
        const newWatchedSections = isWatched ? [] : courseSections.map(sec => sec.name);
        handleWatchChange(newWatchedSections);
    };

    const sectionElems = courseSections.map(sec => (
            <li key={sec.name} onClick={(e) => e.stopPropagation()} className="form-control w-full">
                <label className="label cursor-pointer w-full">
                    <input type="checkbox" checked={watchedSections.includes(sec.name)} onChange={(e) => handleSectionToggle(e,sec.name)} disabled={isLoading} className="checkbox bg-red-50" />
                    { (sec.openseats > 0 && sec.full === false) ? (
                        sec.openseats > sectionWarningLimit ? (
                            <div className="tooltip z-30 tooltip-success tooltip-right" data-tip={`${sec.openseats} seats left`}>
                                <p className='text-center text-green-500 font-bold rounded-full bg-green-300 w-5 bg-opacity-50'>{sec.openseats}</p>
                            </div>
                        ) : (
                            <div className="tooltip z-30 tooltip-warning tooltip-right" data-tip={`${sec.openseats} seats left`}>
                                <p className='text-center text-amber-500 font-extrabold rounded-full bg-yellow-300 w-5 bg-opacity-50'>{sec.openseats}</p>
                            </div>
                        )
                    ) : (
                        <div className="tooltip z-30 tooltip-error tooltip-right" data-tip="Section full">
                            <p className='text-center text-red-500 font-extrabold rounded-full bg-red-300 w-5 bg-opacity-50'>F</p>
                        </div>
                    )}
                    <p className="label-text font-medium w-full text-left text-nowrap">{sec.name}</p>
                </label>
            </li>
    ))

    // let isWatched = false
    // // console.log("Course Name: "+courseName)
    // for (let i = 0; i < (watchingList.length); i++) {
    //     if (watchingList[i].term === courseTerm && watchingList[i].coursekey.replace("-", " ") === courseName) {
    //         isWatched = true
    //     }
    // }


    return (
        <div className="join ml-4 relative">

            <div className={`tooltip tooltip-open ${tooManySections ? "visible" : "invisible"} tooltip-warning`} data-tip="Watching too many sections!">
                <button
                    className={`visible btn btn-sm rounded-full join-item ${isWatched ? 'btn-neutral' : ''}`}
                    onClick={handleWatchAllToggle}
                    disabled={isLoading}
                >
                    {isLoading ? 'Updating...' : (isWatched ? 'Watching' : 'Watch')}
                </button>
            </div>
            <div className="dropdown dropdown-hover rounded-full dropdown-bottom dropdown-end">
                <button tabIndex={0} role="button" className={`btn btn-sm join-item rounded-full ${isWatched ? 'btn-neutral' : ''} text-xl`} disabled={isLoading}>+</button>
                <ul tabIndex={0} className="dropdown-content z-[1] menu grid place-items-center bg-white rounded-box w-52 max-h-52 overflow-x-auto py-2 shadow">
                    {sectionElems}
                </ul>
            </div>
        </div>
    );

    // if (isWatched) {
    //     return (
    //         <div className="join ml-4">
    //             <button className="btn btn-sm join-item rounded-full btn-neutral ">Watching</button>
    //             <div className="dropdown rounded-full dropdown-bottom dropdown-end">
    //                 <button className="btn btn-sm join-item rounded-full btn-neutral text-xl">+</button>
    //                 <ul tabIndex={0} className="dropdown-content z-[1] menu mt-1 grid place-items-center bg-white rounded-box w-52 max-h-52 overflow-x-auto py-2 shadow">
    //                     {sectionElems}
    //                 </ul>
    //             </div>
    //         </div>)
    // } else {
    //     return(
    //         <div className="join ml-4">
    //             <button className="btn btn-sm rounded-full join-item">Watch</button>
    //             {/* <button className="btn btn-sm rounded-full join-item font-bold text-xl">+</button> */}
    //             <div className="dropdown rounded-full dropdown-botton dropdown-end">
    //                 <button className="btn btn-sm join-item rounded-full text-xl">+</button>
    //                 <ul tabIndex={0} className="dropdown-content absolute z-[1] menu mt-1 grid place-items-center bg-white rounded-box w-auto max-h-52 overflow-x-auto py-2 shadow">
    //                     {sectionElems}
    //                 </ul>
    //             </div>
    //         </div>
    //     )
    // }
}

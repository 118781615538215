export default function Home() {

    return (
        <>
            <div className="w-full grid grid-cols-1 place-items-center my-48">
                <p>I don't know how you found this but this page isn't in use anymore. Sorry!</p>
                {/* <p className='text-3xl font-semibold mb-4'>You're unsubscribed!</p>
                <p className='text-lg'>You were unsubscribed to that section the moment you recieved the email!</p>
                {/* <br/> */}
                {/* <p className='text-lg'>If there's an issue or you aren't getting unubscribed, please contact the email in the footer.</p> */}
            </div>
        </>
    );
}



export default function Footer() {

    return (

        <div className="w-full bg-stone-200 py-8 px-4 my-auto min-h-[10vh] border-t-2 border border-stone-300 border-x-0 border-b-0 grid lg:grid-cols-3">
            <div className="col-span-1 grid text-lg place-items-center">
                <a href="https://cheatseat.ca/">cheatseat.ca</a>
            </div>
            <div className="grid col-span-1 text-lg place-items-center">
                <p>CheatSeat is not affiliated with McMaster</p>
            </div>
            <div className="grid col-span-1 place-items-center text-center">
                <p><b className="hover:underline hover:cursor-default">contact [at] cheatseat.ca</b> to get in touch</p>
            </div>
        </div>

    )

}
